main {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;

  &.landing {
    background-color: $color-hues-coral;
  }

  &.account {
    background-color: $color-shades-mint;
    
    .app-content {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      align-self: stretch;
      padding: 1rem .5rem;

      @include TP {
        justify-content: center;
        align-items: center;
        padding: 2rem 0;
      }
      @include TL {
        padding: 3rem 0;
      }
      @include DL {
        padding: 4rem 0;
      }
  
      &__layout {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 28rem;
        max-height: 35rem;
        gap: 1rem;

        @include TP {
          flex-direction: row;
          justify-content: center;
          flex: 1 0 0;
        }
      }
    }
  }

  &.legal {
    background-color: $color-shades-white;

    .app-content {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 1.5rem;
      margin: 0 auto;
      gap: 3rem;
    
      @include ML {
        width: 30rem;
      }
      @include TP {
        padding: 0;
        max-width: none;
        width: 34rem;
      }
      @include TL {
        width: 39;
      }
      @include DS {
        width: 42.5rem;
      }
      @include DL {
        width: 46.5rem;
      }

      h2 {
        text-align: center;
        padding: 2rem 0;

        @include TP {
          padding: 3rem 0;
        }
        @include DS {
          padding: 4rem 0;
        }
      }

      hr {
        width: 100%;
        border-color: $color-shades-slate50;
      }

      &__body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 5rem;

        ol {
          list-style: auto;
          padding-left: 1rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }
  }

  &.redirect {
    background-color: $color-hues-coral;
    color: $color-shades-white;
    align-items: center;
    justify-content: center;

    h3 {
      animation: redirectTitleAnimation .4s ease-in-out infinite;
    }
  }
}
