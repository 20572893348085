.login-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  border-radius: 1rem;
  width: 100%;
  padding: 1.5rem 1rem;
  gap: 2rem;

  @include TP {
    width: 24rem;
    padding: 1.5rem 2rem;
  }
  @include DS {
    width: 20.5rem
  }
  @include DL {
    width: 22.5rem;
  }

  &__icon {
    display: flex;
    padding: 1.5rem;
    justify-content: center;
    align-items: center;
    background-color: $color-hues-eggshell;
    border-radius: 50%;

    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 1rem;

    & > div {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }
  }

  &__separator {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 1rem;

    &-line {
      flex: 1 0 0;
      height: 1px;
      background-color: $color-shades-slate;
    }
  }

  &__social {
    display: flex;
    align-self: stretch;
    gap: .5rem;
  }
}