@use "sass:map";

.container {
  position: relative;
  padding: 0 1.5rem;

  @include ML {
    max-width: map.get($container-widths, "ml");
  }
  @include TP {
    padding: 0;
    max-width: none;
    width: map.get($container-widths, "tp");
  }
  @include TL {
    width: map.get($container-widths, "tl");
  }
  @include DS {
    width: map.get($container-widths, "ds");
  }
  @include DL {
    width: map.get($container-widths, "dl");
  }
}
