.input {
  width: 100%;
  position: relative;
  z-index: 1;

  input {
    width: 100%;
    border: 2px solid $color-shades-slate;
    background: $color-shades-white;
    border-radius: .5rem;
    padding: 0 .75rem;
    height: 3rem;
    font-size: #{fontSize(span, mobile)};
    font-weight: $font-weight-bold;
    line-height: 1.5rem;
    color: $color-shades-darkForest;

    &::placeholder {
      font-weight: $font-weight-regular;
      color: $color-shades-pineGreen;
    }
    &:focus {
      border-color: $color-shades-pineGreen;
    }
  }

  button {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 2;
    cursor: pointer;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
