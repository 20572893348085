button {
  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.cta-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: $font-weight-bold;
  padding: 0.75rem 1rem;
  height: 3rem;
  border-radius: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.2;
  }

  &.primary {
    border: 2px solid $color-shades-white50;
    background: $color-hues-coral;
    color: $color-shades-white;
  }
  &.secondary {
    border: 2px solid rgba(209, 222, 224, 0.5);
    background: $color-shades-mint;
    color: $color-shades-darkForest;
  }
}

.link-button {
  @extend .cta-button;
  text-transform: none;
  font-weight: $font-weight-regular;
  border: none;
  background: transparent;
  color: $color-hues-coral;
  gap: 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.menu-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: .75rem 1rem;
  border-radius: .5rem;
  color: $color-shades-darkForest;
  border: 1px solid $color-shades-slate;
  cursor: pointer;

  &:hover {
    background-color: $color-shades-mint;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.nav-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem;
  margin: 0 1rem;
  align-self: stretch;
  background-color: $color-shades-white;
  border: 1px solid $color-shades-slate;
  border-radius: 1.5rem;
  gap: .5rem;
  cursor: pointer;

  @include TP {
    justify-content: center;
    align-self: flex-start;
    margin: 1rem 0 0;
    animation: accountSubBackSlideIn .4s ease forwards;
  }

  &:hover {
    background-color: $color-shades-mint;
  }

  p {
    display: block;

    @include TP {
      display: none;
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.social-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  height: 3rem;
  border-radius: 1rem;
  border: 2px solid $color-shades-white50;
  cursor: pointer;

  &.facebook { background: #2F80ED }
  &.google { background: #EB5757 }
  &.apple { background: #000 }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}