html {
  font-size: 14px;
  @include DS {
    font-size: 16px;
  }
}

body {
  font-family: $font-family;
  color: $color-shades-darkForest;
  background-color: $color-shades-white;
  font-display: swap;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}
