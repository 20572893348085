.account-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background-color: $color-shades-white;
  border-radius: 1rem;
  width: 100%;
  min-width: 20rem;
  padding: 1.5rem 1rem;
  border: 1px solid $color-shades-slate;
  gap: 2rem;

  @include TP {
    width: 24rem;
    padding: 1.5rem 2rem;

    &:not(:only-child) {
      animation: accountSubScreenSlideIn .4s ease forwards;
    }
  }
  @include DS {
    width: 20.5rem
  }
  @include DL {
    width: 22.5rem;
  }

  &__user {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
    flex: 1 0 0;
    align-self: stretch;
  }

  &__head {
    display: flex;
    padding: 0rem 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: .5rem;
    align-self: stretch;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    flex: 1 0 0;
    align-self: stretch;

    hr {
      width: 100%;
      height: 1px;
      margin: 0;
      border: 0;
      background-color: $color-shades-slate50;
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex: 1 0 0;
    align-self: stretch;

    &-icon {
      display: flex;
      padding: 1.5rem;
      justify-content: center;
      align-items: center;
      background-color: $color-hues-eggshell;
      border-radius: 50%;

      svg {
        width: 4rem;
        height: 4rem;
      }
    }

    &-content {
      display: flex;
      padding: 0rem 0.75rem;
      flex-direction: column;
      align-items: center;
      gap: .5rem;
      text-align: center;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    align-self: stretch;
  }
}