.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;

  @include TP {
    padding: 2rem 0;
  }

  svg {
    width: 7.5rem;
    height: 2rem;
  }
}