.checkbox {
  padding: 0.5rem 0.75rem;

  input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  label {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    cursor: pointer;
  }

  &__checkmark {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: $color-shades-white;
    border: 2px solid $color-shades-slate;
    z-index: 1;
    position: relative;

    &.active {
        background-color: $color-shades-pineGreen;
        border: 2px solid $color-shades-white50;
        display: flex;
        align-items: center;
        justify-content: center;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        z-index: 2;
      }
    }
  }

  &__label {
    flex: 1;
  }

  span {
    color: $color-shades-darkForest;
    font-size: 0.813rem;
    font-weight: 700;
    line-height: 0.813rem;
  }
}
