@font-face {
  font-family: 'Telegraf';
  src:  url('../../fonts/Telegraf-Bold.woff2') format('woff2'),
        url('../../fonts//Telegraf-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src:  url('../../fonts//Telegraf-Regular.woff2') format('woff2'),
        url('../../fonts/Telegraf-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@function fontSize($type, $device: "desktop") {
  @return map-get(map-get($font-sizes, $type), $device);
}

$font-sizes: (
  h1: (
    mobile: 3rem,
    tablet: 4.5rem,
  ),
  h2: (
    mobile: 1.5rem,
    tablet: 1.75rem,
  ),
  h3: (
    mobile: 1.5rem,
  ),
  body: (
    mobile: 1rem,
  ),
  span: (
    mobile: 0.8125rem,
  )
);


// GLOBAL FONT STYLES
h1 {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  text-transform: capitalize;
  line-height: 0.9;
  font-size: #{fontSize(h1, mobile)};
  @include TL {
    font-size: #{fontSize(h1, tablet)};
  }
}

h2 {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  line-height: 1.2;
  font-size: #{fontSize(h2, mobile)};
  @include TL {
    font-size: #{fontSize(h2, tablet)};
  }
}

h3 {
  font-family: $font-family;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  font-size: #{fontSize(h3, mobile)};
}

p {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  line-height: 1.5;
  font-size: #{fontSize(body, mobile)};
  text-transform: none;

  &.bold,
  strong {
    font-weight: $font-weight-bold;
  }

  em {
    font-style: italic;
  }
}

span {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  line-height: 1;
  font-size: fontSize(span, mobile);

  &.bold {
    font-weight: $font-weight-bold;
  }
}