// Landing
@keyframes heroSlideIn01 {
  0% {
    transform: translateY(-2rem);
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}

@keyframes heroSlideIn01M {
  0% {
    transform: translateX(-2rem);
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}

@keyframes heroSlideIn02 {
  0% {
    transform: translateY(2rem);
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}

@keyframes heroSlideIn02M {
  0% {
    transform: translateX(2rem);
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}

// Account
@keyframes accountSubBackSlideIn {
  0% {
    transform: translateX(2rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}

@keyframes accountSubScreenSlideIn {
  0% {
    transform: translateX(-2rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

@keyframes redirectTitleAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}