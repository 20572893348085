.hero {
  display: flex;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  &__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    @include TP {
      flex-direction: row;
      gap: 0;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      @include TP {
        flex: 1;
        align-items: start;
        max-width: 19rem;
        gap: 3rem;
      }
      @include TL {
        max-width: 24rem;
        gap: 4rem;
      }
      @include DS {
        max-width: 26rem;
      }
      @include DL {
        max-width: 28.5rem;
      }

      &__logo {
        svg {
          width: 11.25rem;
          height: 3rem;
        }
      }

      &__copy {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h1,
        h2 {
          color: $color-shades-white;
          text-align: center;

          @include TP {
            text-align: start;
          }
        }
      }
      
      &__links {
        display: none;
        
        @include TP {
          display: flex;
          gap: 1.5rem;
        }

        &-store {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 1rem;

          svg {
            &:nth-child(1) {    
              @include TP {
                width: 7.5rem;
              }
              @include DS {
                width: 7.5rem;
              }
            }

            &:nth-child(2) {    
              @include TP {
                width: 8.444rem;
              }
              @include DS {
                width: 8.43rem;
              }
            }
          }
        }

        &-qr {
          background-color: $color-shades-white;
          padding: 1rem;
          border-radius: 1rem;

          svg {
            width: 6.5rem;
            height: 6.5rem;

            @include DS {
              width: 7.5rem;
              height: 7.5rem;
            }
          }


        }
      }
    }

    &-right {
      position: relative;
      width: 100%;
      height: 35rem;
      min-width: 18rem;
      max-width: 25rem;
      transform: perspective(23rem);

      @include TP {
        height: 36rem;
        max-width: 19rem;
      }
      @include TL {
        height: 46rem;
        max-width: 29rem;
      }
      @include DS {
        max-width: 31.5rem;
      }
      @include DL {
        max-width: 34.5rem;
      }

      img {
        height: auto;
        position: absolute;
        max-width: 15rem;
        border-radius: 1.5rem;
        background-color: $color-shades-white;

        &.img01 {
          animation: heroSlideIn01M 1s ease forwards;
          z-index: 2;
          box-shadow: 0 1rem 10rem 0 $color-hues-coral;
          left: 0;
          top: 0;
          opacity: 0;
        }
        &.img02 {
          animation: heroSlideIn02M 1s ease forwards;
          z-index: 1;
          right: 0;
          bottom: 0;
          opacity: 0;
        }
        
        @include TP {
          &.img01 {
            animation: heroSlideIn01 1s ease forwards;
          }
          &.img02 {
            animation: heroSlideIn02 1s ease forwards;
          }
        }

        @include TL {
          max-width: 20rem;
          border-radius: 2rem;
        }
      }
    }
  }
}
