@use "sass:map";

// COLORS
$colors: (
  hues: (
    "coral": hsl(10, 100%, 70%),
    "tangerine": hsl(20, 95%, 80%),
    "eggshell": hsl(30, 90%, 90%),
    "vermilion": hsla(5, 90%, 60%, 1),
  ),
  shades: (
    "darkForest": hsl(190, 30%, 20%),
    "pineGreen": hsl(180, 30%, 30%),
    "slate": hsl(190, 20%, 85%),
    "slate50": hsla(190, 20%, 85%, 0.5),
    "mint": hsl(180, 40%, 97%),
    "white": hsl(0, 0%, 100%),
    "white50": hsla(0, 0%, 100%, 0.5),
  ),
);

$color-hues-coral: map.get($colors, "hues", "coral");
$color-hues-tangerine: map.get($colors, "hues", "tangerine");
$color-hues-eggshell: map.get($colors, "hues", "eggshell");
$color-hues-vermilion: map.get($colors, "hues", "vermilion");
$color-shades-darkForest: map.get($colors, "shades", "darkForest");
$color-shades-pineGreen: map.get($colors, "shades", "pineGreen");
$color-shades-slate: map.get($colors, "shades", "slate");
$color-shades-slate50: map.get($colors, "shades", "slate50");
$color-shades-mint: map.get($colors, "shades", "mint");
$color-shades-white: map.get($colors, "shades", "white");
$color-shades-white50: map.get($colors, "shades", "white50");


//FONTS
// Font familis
$font-family: "Telegraf", sans-serif;

// Weight
$font-weight-regular: 400;
$font-weight-bold: 600;


//LAYOUT
// Grid Breakpoints
$grid-breakpoints: (
  ml: 378px,
  tp: 672px,
  tl: 980px,
  ds: 1200px,
  dl: 1440px,
);

// Container sizing
$container-widths: (
  ml: 30rem,
  tp: 44rem,
  tl: 59rem,
  ds: 64.5rem,
  dl: 70.5rem,
);