.app-footer {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  color: $color-shades-white;
  height: 5.571rem;

  @include TP {
    height: 3.786rem;
  }

  @include DS {
    height: 4.357rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-align: center;

    @include TP {
      flex-direction: row;
      text-align: start;
    }

    &-links {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      @include DS {
        gap: 1rem;
      }

      a {
        color: $color-shades-white;
      }

      hr {
        display: block;
        width: 0.25rem;
        height: 0.25rem;
        border: 0;
        background: $color-hues-tangerine;
        border-radius: 50%;
        margin: 0;
      }
    }
  }

  &.light {
    color: $color-shades-pineGreen;

    .app-footer__content-links {
      a {
        color: $color-shades-pineGreen;
      }
      hr {
        background: $color-shades-slate;
      }
    }
  }
}
